import { Component } from 'react';
import { IWorkExperience } from '../interfaces/resume.interface';

class ResumeTile extends Component<IWorkExperience> {
  showMoreClicked: boolean = false;
  showMoreClick() {
    // not the way to handle state! but will do, for now...
    this.setState({showMoreClicked: true})
    this.showMoreClicked = true;
  }
  render() {
    return (
      <div className="ResumeTile">
        <div className="company-div">
          <p className="resume-company"><a target="_blank" rel="noreferrer" href={this.props.link}>{this.props.company}</a> <span className="resume-dates">{this.props.dates}</span></p>
        </div>
        <p className="resume-role">{this.props.role}</p>
          <ul className="resume-accomps">{
            this.showMoreClicked ? 
              this.props.accomplishments.map(({val, key}) => 
                <li
                  key={key}
                >{val}</li>
              ) 
            : <li 
                className="show-more"
                onClick={() => this.showMoreClick()}>...</li>
          }</ul>
      </div>
    );
  }
}

export default ResumeTile;