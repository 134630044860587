import { Component } from 'react';

class ErrorPage extends Component {
  render() {
    return (
      <div className="Error">
        Whoops! Something went wrong. Head on back to the homepage before you get the developer fired, wouldja?
      </div>
    );
  }
}

export default ErrorPage;
