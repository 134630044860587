import { Component } from 'react';

import './App.css';

import NavBar from './components/NavBar';
import { Outlet } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-jack">Jack Masterson</div>
        <NavBar />
        <Outlet />
      </div>
    );
  }
}

export default App;
