import { Component } from 'react';
import { Link } from 'react-router-dom';
import { INav } from '../interfaces/nav.interface';

class NavLink extends Component<INav> {
  render() {
    return (
      <div className="NavLink">
        <Link to={this.props.name === 'Home' ? '/' : this.props.name.toLowerCase()}>{this.props.name}</Link>
      </div>
    );
  }
}

export default NavLink;