
import { Component } from 'react';

interface Project {
  title: string;
  url?: string;
  description: string;
  stack: string[];
  key: number;
  live: boolean;
}
interface ProjectsData {
  type: string;
  projects: Project[];
  key: number;
}
class Projects extends Component {
  render() {
    const projectData: ProjectsData[] = [
      {
        type: 'Word Games',
        key: 0,
        projects: [
          {
            title: 'MishMash',
            key: 0,
            url: 'https://mishmash.day',
            description: 'A word game played by over a thousand people where a user has to unscramble four words each day. I just want to be the next Wordle, and I don\'t think that\'s too much to ask!',
            live: true,
            stack: [
              'ExpressJS',
              'Typescript',
              'Socket.io',
            ]
          }, 
          {
            title: 'Wordhop',
            key: 1,
            url: 'https://mishmash.day/wordhop',
            description: 'My very first word game attempt. Defunct for a while, it was brought back by popular demand and is visually very simple. Change one letter at a time to get from the first to the last word.',
            live: true,
            stack: [
              'ExpressJS',
              'Typescript',
            ]
          },
          {
            title: 'Mishmash Lightning',
            key: 2,
            url: 'https://mishmash.day/lightning',
            description: 'A different take on the original Mishmash. You get 45 seconds to unscramble as many four-letter words as you can. Personally, my favorite. Critically, played by few.',
            live: true,
            stack: [
              'ExpressJS',
              'Typescript',
            ]
          },
          {
            title: 'Mishmash: The Synonym Challenge',
            key: 3,
            url: 'https://mishmash.day/smishmash',
            description: 'I had an aunt who refused to play MishMash because the timed aspect frustrated her. So I tried to make one that wouldn\'t involve being timed. I spent very little time on it, and hate playing it because the synonym wordbank I use is...questionable. I could leave this one off my resume, but I\'m trusting you here. Plus, if you clicked on the others, they\'ll take you there anyway eventually.',
            live: true,
            stack: [
              'ExpressJS',
              'Typescript',
            ]
          }
        ]
      },
      {
        type: 'Side Hustles',
        key: 2,
        projects: [
          {
            title: 'LivnList',
            live: false,
            key: 4,
            description: 'My first side project in React, I worked with a team on the Front End building out a webapp that would make it easier to coordinate plans. I stepped away when full-time job responsibilities increased to the point where I wasn\'t able to devote much time anymore to the project. I worked on the user log in flow and the plan-booking flow.',
            stack: [
              'React',
              'Typescript',
              'JWT',
            ]
          },
          {
            title: 'Chat in the Box',
            key: 5,
            live: false,
            description: 'First in React, then in Vue - this was an online webapp keyboard meant to be used as an accessibility feature on an iPad for a friend who can\'t type on modern keypads. It linked into the Twilio API to send and receive actual text messages. However, it didn\'t really solve the problem I was trying to get at and wasn\'t as useful as I wanted it to be, so it is now defunct. I learned a whole lot, though.',
            stack: [
              'React',
              'Vue (rewrite)',
              'Twilio API',
              'ExpressJS',
            ]
          },
        ]
      },
      {
        type: 'Efficiency and Automation',
        key: 1,
        projects: [
          {
            title: 'Research Formatter',
            key: 2,
            live: false,
            description: 'My fiancee has to format transcribed interviews in a way that takes hours and sometimes days. I wrote a script in Node that does it in under a second so that she\'d hang out with me more. It\'s been shared with her cohort at HBS and is run via command line.',
            stack: [
              'NodeJS',
            ]
          }, 
          {
            title: 'Class Sign Up',
            key: 3,
            live: true,
            description: 'Not "live" in the sense that it\'s publicly available, but a friend kept missing sign ups for an exercise class. The link goes live each day at 2:30am, so I wrote him a script in Puppeteer that runs on a cron. Let me tell you, he\'s fitter than ever.',
            stack: [
              'Puppeteer',
            ]
          },
        ]
      }
    ]
    return (
      <div className="Projects">
        {
          projectData.map(({ type, projects, key }) =>
          <div key={key} className="project-type-container">
            <h3>{type}</h3>
            <div className="projects-container">
            {
              projects.map(({title, live, description, stack, url, key: projectKey}) =>
                <div key={projectKey} className="project-container">
                  <p className="project-title">{live && url ? <a className="project-link" target="_blank" href={url}>{title}</a> : title}</p>
                  <p className="project-non-title project-stack stack-title">{stack.join(', ')}</p>
                  <p className="project-non-title description">{description}</p>
                  <p>Live: {live ? 'yes' : 'no'}</p>
                </div>
              )
            }
            </div>
          </div>
          )
        }
      </div>
    );
  }
}

export default Projects;
