import { Component } from 'react';

// import logo from '../me-tri.JPEG';
// something about this importseems to keep it from having to re-render? maybe not...
import '../App.css';

class Homepage extends Component {
  render() {
    return (
      <div className="Homepage">
        <header className="Homepage-header">
          <img src="../me-tri.JPEG" className="Homepage-img" alt="Falmouth Triathlon" />
        </header>
        <div className="bio">
          <p>Jack Masterson is a Software Engineer from Cambridge, Massachusetts with over 7 years of experience working for adtech companies and an enterprise lead-generation company. Personal interests include improv comedy, crossword puzzles, swimming in the ocean, and tinkering with the latest tech.</p>
        </div>
        <div className="homepage-disclaimer-container">
          <p className="homepage-disclaimer">This site was built with React. Not because it's practical, but because I can.</p>
          <p className="homepage-disclaimer">No one with a sense for aesthetics was consulted in the making of this website.</p>
        </div>
      </div>
    );
  }
}

export default Homepage;
