import { Component } from 'react';
import ResumeTile from '../components/ResumeTile';
import { IWorkExperience } from '../interfaces/resume.interface';

class Resume extends Component {
  render() {
    let experience: IWorkExperience[] = [
      {
        company: 'CafeMedia',
        role: 'Staff Software Engineer',
        dates: '2020 - Present',
        link: 'https://cafemedia.com/',
        id: 5,
        accomplishments: [
          { val: 'Feature flag framework', key: 6 },
          { val: 'Ad insertion', key: 1},
          { val: 'System monitoring', key: 3 },
          { val: 'Automated alerting', key: 4 },
          { val: 'Ad block detection', key: 2 },
          { val: 'Content-specific video playlists', key: 5 },
        ]
      }, {
        company: 'HomeAdvisor',
        role: 'Front End Developer',
        link: 'https://www.homeadvisor.com/',
        id: 4,
        dates: '2019 - 2020',
        accomplishments: [
          {val: 'Happy Path rewrite', key: 1},
          {val: 'Instant Connect feature', key: 2}
        ]
      }, {
        company: 'EMX Digital',
        role: 'Software Engineer',
        link: 'https://big-village.com/emx/',
        id: 3,
        dates: '2017-2019',
        accomplishments: [
          {val: 'Open source ad exchange adapter', key: 1},
          {val: 'Custom-built video player', key: 2},
          { val: 'Client version updates automation', key: 3 },
          { val: 'Code rearchitecting', key: 4 },
        ]
      }, {
        company: 'Cafemedia',
        role: 'Software Engineer',
        id: 2,
        link: 'https://cafemedia.com/',
        dates: '2016-2017',
        accomplishments: [
          {val: 'Automated compliance monitoring', key: 1},
          {val: 'Live site performance scraper', key: 2},
          {val: 'Recommendation widget', key: 3},
        ]
      }, {
        company: 'The Publisher Desk',
        role: 'JavaScript Developer',
        link: 'https://www.publisherdesk.com/',
        id: 1,
        dates: '2015-2016',
        accomplishments: [
          {val: 'Deferred feature bundling', key: 1},
          {val: 'Spot.IM integration', key: 2},
          {val: 'Prebid updates', key: 3},
        ]
      }
    ]
    return (
      <div>
        <p className="resume-disclaimer">For the most up-to-date version of my resume, please reach out via the Contact page</p>
        <div className="Resume">
          {
            experience.map(({company, role, link, id, dates, accomplishments}) => 
              <ResumeTile
                key={id}
                id={id}
                company={company}
                link={link}
                role={role}
                dates={dates}
                accomplishments={accomplishments}
              />
            )
          }
        </div>
      </div>

    );
  }
}

export default Resume;
