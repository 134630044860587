import { Component } from 'react';

import NavLink from "./NavLink";
import {INav} from '../interfaces/nav.interface';

class NavBar extends Component {
  render() {
    let links: INav[] = [
      {
        name: 'Home',
        key: 0,
      }, {
        name: 'Projects',
        key: 1,
      }, {
        name: 'Resume',
        key: 2,
      }, {
        name: 'Contact',
        key: 3
      }
      // , {
      //   name: 'Fun',
      //   key: 3,
      // }
    ];
    return (
      <div className="NavBar">
        {
          links.map(({name, key}) => 
            <NavLink
              key={key}
              name={name}
            />
          )
        }
        <div className="NavBar-divider"></div>
      </div>
    );
  }
}

export default NavBar;