
import { ClientRequest } from 'http';
import { Component } from 'react';

import logo from '../me-walking.png';

class Contact extends Component {
  state = {
    msg: '',
    contact: '',
    name: '',
    errorEmail: false,
    requesting: false,
    somethingWentWrong: false,
    thanks: false,
  };
  nameTyped(target: EventTarget) {
    const name = (target as HTMLInputElement).value;
    this.setState({
      name
    });
  }
  validateEmail(email: string) {
    return /^\S+@\S+\.\S+$/.test(email);
  }
  // put validation around this
  contactTyped(target: EventTarget) {
    this.setState({
      errorEmail: false
    });
    const contact = (target as HTMLInputElement).value;
    if (this.validateEmail(contact)) {
      this.setState({
        contact
      });
    } else {
      this.setState({
        errorEmail: true
      });
    }

  }
  messageTyped(target: EventTarget) {
    const msg = (target as HTMLInputElement).value;
    this.setState({
      msg
    });
  }
  clearForm() {
    this.setState({
      msg: '',
      contact: '',
      name: '',
      errorEmail: false
    });
    const name = document.querySelector('.form-name') as HTMLInputElement;
    const email = document.querySelector('.form-email') as HTMLInputElement;
    const msg = document.querySelector('.form-text') as HTMLInputElement;
    name.value = '';
    email.value = '';
    msg.value = '';
  }
  async submitForm() {
    if (this.state.requesting) {
      return;
    }
    // seems like state is missing the last letter in some instances where I type fast and click submit immediately
    const state = this.state;
    const contactName = state.name;
    const contactEmail = state.contact;
    const msg = state.msg;
    if (!contactName || !contactEmail || !msg) {
      return;
    }
    // console.log('name, contact, msg: ', contactName, contactEmail, msg);
    this.clearForm();

    this.setState({
      requesting: true
    });

    const request = await fetch(`https://jack-masterson.com/new-msg`, {
      mode: 'no-cors',
      method: 'POST',
      body: JSON.stringify({contactName, contactEmail, msg})
    });
    
    this.setState({
      requesting: false
    });

    if (request.status !== 200) {
      this.setState({
        somethingWentWrong: true
      });
      setTimeout(() => {
        this.setState({
          somethingWentWrong: false
        });
      }, 5000);
    } else {
      this.setState({
        thanks: true
      });
    }
  }
  render() {
    return (
      <div className="Contact">
        <div className="prof-pic-div">
          <img id="prof-pic-img" src={logo} alt="Wedding Wave" width="150" />
        </div>
        <div className={this.state.somethingWentWrong ? "something-went-wrong" : "something-went-wrong hidden"}>
          There was a problem submitting your contact information. Please try again in a few moments.
        </div>
        <div className="contact-blurb">
          <p>Want a copy of my resume? Just want to say hi? Reach out!</p>
        </div>
        <div className="contact-form">
          <input className="form-name" onKeyUp={(e) => this.nameTyped(e.target)} placeholder="Your name" />
          <input className={this.state.errorEmail ? 'error-email form-email' : 'form-email'} onKeyUp={(e) => this.contactTyped(e.target)} placeholder="Your email" />
          <br />
          <textarea className="textarea form-text" name="textarea" rows={5} cols={5} onKeyUp={(e) => this.messageTyped(e.target)} placeholder="Your message" />
          <br />
          {
            this.state.thanks ? <p>Thanks!</p> :
            <button className={this.state.requesting ? 'disabled' : ''} onClick={() => this.submitForm()}>Submit</button>
          }
        </div>
        <p className="funny-blurb">I could have just shared my email and contact info, but a form submission that will send me an email is <i>so</i> much more fun.</p>
      </div>
    );
  }
}

export default Contact;
